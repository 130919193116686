<template>
  <div id="question-content" class="page-transition">
    <h2 class="fw-700">{{ question.title }}</h2>
    <h5 class="fw-500 text-muted my-3" style="line-height: 1.8rem;">{{ question.body }}</h5>
    <div class="options my-2 py-2 d-flex flex-wrap justify-content-center" :data-min-options="question.min_options" :data-max-options="question.max_options">
      <Option ref="options_list"
              v-for="(option, index) in question.options" v-bind:key="index"
              v-bind:option_index="index"
              v-bind:option="option.option_text"
              v-bind:is_exclusive="option.is_exclusive"
              v-bind:max="question.max_options"
              v-bind:min="question.min_options"
              v-bind:hour_value="option.hour_value"
              v-bind:hour_factor="option.hour_factor"
              v-bind:dollar_value="option.dollar_value"
              v-bind:dollar_factor="option.dollar_factor"
              v-bind:is_active="(questionAnswers.includes(index))"
              v-bind:tooltip_text="option.tooltip_text"
              v-bind:badge_text="option.badge_text"
              v-bind:badge_color="option.badge_color"
              v-bind:icon="option.icon_name"></Option>
    </div>
  </div>
</template>

<script>
import json from "@/data.json";
import Option from '@/components/Option.vue';

export default {
  name: 'Question',
  components: {
    Option
  },
  props: {
    questionAnswers: Array
  },
  data() {
    return {
      settings: this.$store.state.json_data.settings,
      data: this.$store.state.json_data.questions,
      question: json.questions[this.$route.params.index],
      totalTime: 0,
      totalPrice: 0,
    }
  },
  mounted() {
    (this.$route.params.index == 0) ? this.$parent.backBtnHidden = true : this.$parent.backBtnHidden = false


    this.totalTime = this.$store.state.initial_time
    this.totalPrice = this.$store.state.initial_price

    // for (let i=0; i<this.$route.params.index; i++) {
    //   this.totalTime += this.$store.state.questions_answers[i]?.total_time ?? 0
    //   this.totalPrice += this.$store.state.questions_answers[i]?.total_price ?? 0
    // }

    this.$store.state.questions_answers.forEach((question, index) => {
      if ( index !== parseInt(this.$route.params.index) ) {
        this.totalTime += question.total_time
        this.totalPrice += question.total_price
      }
    })

    this.calculateQuestionTotal()

    this.$root.$on('countSelectedOptions', () => {
      this.calculateQuestionTotal()
    });

  },
  methods: {
    calculateQuestionTotal: function() {
      this.$parent.optionsSelectedCounter = 0
      this.$parent.questionTimeTotal = 0
      this.$parent.questionPriceTotal = 0
      this.$parent.questionAnswers = []
      this.$refs.options_list.forEach((option, index) => {
        if (option.$data.isActive === true) {
          this.$parent.optionsSelectedCounter++
          this.$parent.questionTimeTotal += option.$props.hour_value
          this.$parent.questionTimeTotal *= option.$props.hour_factor
          this.$parent.questionPriceTotal += option.$props.dollar_value
          this.$parent.questionPriceTotal *= option.$props.dollar_factor
          this.$parent.questionAnswers.push(index)
        }
      });

      (this.$parent.optionsSelectedCounter < this.question.min_options) ? this.$parent.nextBtnDisabled = true : this.$parent.nextBtnDisabled = false

      this.calculateProgram()
    },
    calculateProgram: function () {
      let calculatingTotalTime = this.$parent.questionTimeTotal + this.totalTime
      let calculatingTotalPrice = this.$parent.questionPriceTotal + this.totalPrice

      if ( this.$route.params.index == 0 && this.$parent.optionsSelectedCounter == 0 ) {
        calculatingTotalTime = 0
        calculatingTotalPrice = 0
        this.$parent.estimateHide = true
      } else {
        this.$parent.estimateHide = false
      }
      if ( calculatingTotalPrice === 0 ) {
        this.$parent.minCalc = 0
        this.$parent.maxCalc = 0
      } else {
        this.$parent.minCalc = calculatingTotalPrice - (calculatingTotalPrice * this.settings.questions_price_diff / 100)
        this.$parent.maxCalc = calculatingTotalPrice + (calculatingTotalPrice * this.settings.questions_price_diff / 100)
      }

      if ( calculatingTotalTime === 0 ) {
        this.$parent.timeframeMinCalc = 0
        this.$parent.timeframeMaxCalc = 0
        this.$parent.timeframeString = "—"
      } else {
        this.$parent.timeframeMinCalc = calculatingTotalTime - (calculatingTotalTime * this.settings.questions_time_diff / 100)
        this.$parent.timeframeMaxCalc = calculatingTotalTime + (calculatingTotalTime * this.settings.questions_time_diff / 100)
        if ( this.$parent.timeframeMinCalc/24/7 < 1 ) {
          this.$parent.timeframeMinCalc = this.$parent.timeframeMinCalc/24
          this.$parent.timeframeMaxCalc = this.$parent.timeframeMaxCalc/24
          this.$parent.timeframeString = Math.round(this.$parent.timeframeMinCalc) + " — " + Math.round(this.$parent.timeframeMaxCalc) + " Days"
        } else {
          this.$parent.timeframeMinCalc = this.$parent.timeframeMinCalc/24/7
          this.$parent.timeframeMaxCalc = this.$parent.timeframeMaxCalc/24/7
          if (Math.round(this.$parent.timeframeMinCalc) === Math.round(this.$parent.timeframeMaxCalc))
            this.$parent.timeframeString = Math.round(this.$parent.timeframeMinCalc) + " Weeks"
          else
            this.$parent.timeframeString = Math.round(this.$parent.timeframeMinCalc) + " — " + Math.round(this.$parent.timeframeMaxCalc) + " Weeks"
        }

      }
    },
  }
}
</script>