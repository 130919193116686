<template>
  <div class="question_option mx-0 mx-lg-2 my-2">
    <b-button v-bind:id="'option-' + option_index" class="w-100 btn btn-secondary option rounded-full fw-600 fs-5 px-4 py-3 d-flex justify-content-center justify-content-lg-start align-items-center shadow-none"
         v-on:click="checkToggle"
         v-bind:class="{active: isActive}"
         :data-exclusive="is_exclusive"
         :data-hour-value="hour_value"
         :data-hour-factor="hour_factor"
         :data-dollar-factor="dollar_value"
         :data-dollar-value="dollar_factor">
      <div class="option-label d-inline-block" v-bind:class="(icon) ? 'has_icon' : ''" :style="image"></div>{{ option }}
    </b-button>
    <b-tooltip container="#question-content" v-bind:triggers="tooltip_trigger" v-bind:target="'option-' + ((tooltip_text) ? option_index : 'no' )" variant="light" custom-class="fw-500 fs-6 rounded-5" v-bind:placement="tooltip_placement"><div v-html="badge_content" /><div v-html="tooltip_text" /></b-tooltip>
  </div>
</template>

<script>
export default {
  name: 'Option',
  props: {
    option_index: Number,
    option: String,
    is_exclusive: Boolean,
    min: Number,
    max: Number,
    totalSelectedOptions: Number,
    icon: String,
    hour_value: Number,
    hour_factor: Number,
    dollar_value: Number,
    dollar_factor: Number,
    is_active: Boolean,
    tooltip_text: String,
    badge_text: String,
    badge_color: String,
  },
  data() {
    return {
      isActive: this.is_active,
      image: null,
      optionsSelected: 0,
      badge_content: null,
      exclusiveSelected: false,
      tooltip_trigger: 'hover focus',
      tooltip_placement: 'bottom'
    }
  },
  methods: {
    tooltipTrigger: function() {
      const is_touch_device = ("ontouchstart" in window) || window.DocumentTouch && document instanceof window.DocumentTouch;
      this.tooltip_trigger = is_touch_device ? "focus" : "hover"
    },
    tooltipPlacement: function() {
      const is_touch_device = ("ontouchstart" in window) || window.DocumentTouch && document instanceof window.DocumentTouch;
      this.tooltip_placement = is_touch_device ? "top" : "bottom"
    },
    hexToRgba: function(hex, opacity) {
      // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
      var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, function(m, r, g, b) {
        return r + r + g + g + b + b;
      });

      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? parseInt(result[1], 16) + ', ' + parseInt(result[2], 16) + ', ' + parseInt(result[3], 16) + ', ' + opacity : null;
    },
    rerenderIcon: function () {
      this.image = { backgroundImage: 'url(' + ((this.icon && !this.isActive) ? '/options_icons/' + this.icon : require(this.max === 1 || this.is_exclusive ? '@/assets/icons/single-select-icon.svg' : '@/assets/icons/multi-select-icon.svg') ) + ')' }
    },
    checkToggle: function() {

      this.optionsSelected = 0
      this.exclusiveSelected = false
      this.$parent.$refs.options_list.forEach((option) => {
        if (option.$data.isActive === true) {
          this.optionsSelected++;
          if (option.$props.is_exclusive)
            this.exclusiveSelected = true
        }
      });

      if (this.isActive) {
        this.isActive = false;
        this.rerenderIcon();
      } else {

        if (this.max === 1 || this.is_exclusive || this.exclusiveSelected) {
          const all_options = document.querySelectorAll('.options .option')
          for (let i = 0; i < all_options.length; i++) {
            this.$root.$emit('deActivate');
          }
        } else if (this.max <= this.optionsSelected) {
          if (this.exclusiveSelected) {
            this.$parent.$parent.$data.showExclusiveWarning = true
          } else {
            this.$parent.$parent.$data.showMaxWarning = true
          }
          return
        }
        this.isActive = !this.isActive;
      }

      this.$root.$emit('countSelectedOptions')
      this.rerenderIcon()
    }
  },
  mounted: function () {

    this.tooltipTrigger()
    this.tooltipPlacement()

    if (this.badge_text) {
      this.badge_content = '<div class="badge w-100 my-2 py-2 fw-600 rounded-3" style="font-size: 15px; color: ' + ((this.badge_color) ? this.badge_color : "#3D68FF" ) + ' ;background-color: rgba(' + ((this.badge_color) ? this.hexToRgba(this.badge_color, .1) : this.hexToRgba('#3D68FF', .1) ) + ')">' + this.badge_text + '</div>'
    }

    this.rerenderIcon();
    // Deactivate Button
    this.$root.$on('deActivate', () => {
      this.isActive = false;
      this.rerenderIcon()
    })
  }
}
</script>