<template>
  <div class="position-relative">
    <div class="col-12 col-lg-8 offset-0 offset-lg-2" style="min-height: 60vh;">
      <div class="text-center">
          <div class="progress mx-auto my-4" style="height: 9px; width: 140px;">
            <div class="progress-bar rounded-full" role="progressbar" v-bind:style="{ width: progressPercent+'%' }" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        <div class="alert alert-warning alert-dismissible maximum-selection-alert" :class="{show: showMaxWarning}" role="alert">
          You can select <strong>Maximum of {{ question.max_options }}</strong> Options.
          <button type="button" class="btn-close" v-on:click="showMaxWarning = false"></button>
        </div>
<!--        <div class="alert alert-warning alert-dismissible maximum-selection-alert" :class="{show: showExclusiveWarning}" role="alert">-->
<!--          You have selected an exclusive Option. Means you can not select any other options along.-->
<!--          <button type="button" class="btn-close" v-on:click="showExclusiveWarning = false"></button>-->
<!--        </div>-->
          <Question v-bind:questionAnswers="questionAnswers"></Question>
      </div>
    </div>
    <div class="rounded-5 d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-center py-4 px-5 position-sticky mt-5" style="background-color: #F6F7FB; bottom: 1rem;">
      <div class="meta-data d-flex justify-content-center py-3" :class="{hide: estimateHide}">
        <div class="minmax px-4 border-end border-secondary border-2 fw-700 pt-3 fs-5" style="min-width: 150px;">
          <span class="min-calc">{{ minCalc | toCurrency }}</span> — <span class="max-calc">{{ maxCalc | toCurrency }}</span>
        </div>
        <div class="timeframe px-4 fw-700 pt-3 fs-5" style="min-width: 150px;">
          <span class="timeframe-calc">{{ timeframeString }}</span>
        </div>
      </div>
      <div class="buttons col-12 col-lg-3 text-center text-lg-end mt-3 mt-lg-0">
        <button @click="$router.go(-1)" :class="{hide: backBtnHidden}" class="btn fw-700 px-6 fs-5">Back</button>
        <button v-on:click="goToNext" :class="{disabled: nextBtnDisabled}" class="btn btn-primary fw-700 fs-5 px-6 py-3 rounded-4">Next</button>
<!--        <router-link :to="{ name: 'Questions', params: { index: parseInt(this.$route.params.index)+1 } }" class="btn btn-primary fw-700 fs-5 px-6 py-3 rounded-4">Next</router-link>-->
      </div>
    </div>
  </div>
</template>

<script>
import json from "@/data.json";
import Question from "@/components/Question.vue";


export default {
  name: 'Questions',
  components: {
    Question,
  },
  data() {
    return {
      settings: this.$store.state.json_data.settings,
      data: this.$store.state.json_data.questions,
      question: json.questions[this.$route.params.index],
      progressPercent: (( sessionStorage.getItem('last_question_index') && parseInt(sessionStorage.getItem('last_question_index')) >= this.$route.params.index ) ? (parseInt(sessionStorage.getItem('last_question_index'))) / json.questions.length * 100 : (this.$route.params.index-1) / json.questions.length * 100 ),
      minCalc: 0,
      maxCalc: 0,
      questionTimeTotal: 0,
      questionPriceTotal: 0,
      optionsSelectedCounter: 0,
      questionAnswers: this.$store.state.questions_answers[this.$route.params.index]?.options ?? [],
      timeframeMinCalc: 0,
      timeframeMaxCalc: 0,
      timeframeString: "—",
      showMaxWarning: false,
      showExclusiveWarning: false,
      backBtnHidden: true,
      nextBtnDisabled: true,
      estimateHide: true
    }
  },
  methods: {
    goToNext: function() {

      this.$store.commit('saveState', [{question_index: parseInt(this.$route.params.index), total_time: this.questionTimeTotal, total_price: this.questionPriceTotal, options: this.questionAnswers}])

      if ( parseInt(this.$route.params.index)+1 >= this.data.length ) {
        this.$router.push({ name: 'Summary' });
      } else {
        this.$router.push({ name: 'Questions', params: { index: String(parseInt(this.$route.params.index)+1) } });
      }
    }
  },
  mounted() {
    sessionStorage.setItem('last_question_index', String(parseInt(this.$route.params.index)))
    setTimeout(() => this.progressPercent = (this.$route.params.index) / json.questions.length * 100, 100);
  }
}
</script>
